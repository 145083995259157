import { useEffect } from "react";
import fetchJson from "../lib/fetchJson";
import { useRouter } from "next/router";

const Profile = () => {
  const router = useRouter()
  const checkUser = async () => {
    const user = await fetchJson('/api/user');
    if(user && user.isLoggedIn){
      const userData = user.user
      if(userData.role === 'USER'){
        await router.push('/checkin')
      }else{
        await router.push('/admin')
      }
    }else{
      await router.push('/login')
    }
  }
  useEffect( () => {
    checkUser()
  }, [])
  return (<>
    <h3>Loading</h3>
  </>)
}

export default Profile;